import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useShopSession } from 'features/shopSession/hooks';

import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';
import FullSizeTable from 'components/molecules/table/fullSizeTable/FullSizeTable';

const columns = [
  {
    key: 'name',
    dataKey: 'name',
    titleTranslationKey: 'views.admin.shop-session.products.name',
    width: 400,
  },
  {
    key: 'quantity',
    dataKey: 'quantity',
    titleTranslationKey: 'views.admin.shop-session.products.quantity',
    width: 200,
  },
  {
    key: 'sku',
    dataKey: 'sku',
    titleTranslationKey: 'views.admin.shop-session.products.sku',
    width: 200,
  },
];

function AdminPageShopSessionProducts() {
  const { t } = useTranslation();
  const { shopId, shopSessionId } = useParams();
  const shopSession = useShopSession(shopId, shopSessionId);

  const translatedColumns = columns.map((column) => ({
    ...column,
    title: t(column['titleTranslationKey']),
  }));

  if (!shopSession) {
    return null;
  }

  const sectionTitle =
    shopSession?.data.user.role === 'regular_shopping'
      ? t('views.admin.shop-session.products.heading-products')
      : t('views.admin.shop-session.products.heading-loaded-products');

  return (
    <TabContentSection sectionTitle={sectionTitle}>
      <FullSizeTable data={shopSession.data.products} columns={translatedColumns} />
    </TabContentSection>
  );
}

export default AdminPageShopSessionProducts;
