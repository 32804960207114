import styled from 'styled-components';

const StatusIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ status }) => (status ? '#02c798' : '#ff2d55')};
`;

export default StatusIcon;
