import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useShop } from 'features/shops/hooks';

import Dashboard from 'components/organisms/layouts/dashboard/Dashboard';
import DashboardHeadings from 'components/molecules/dashboardHeadings/DashboardHeadings';
import LayoutLoader from 'components/molecules/loader/layout/LayoutLoader';
import RouteTabsContainer from 'components/molecules/routeTabs/routeTabsContainer/RouteTabsContiner';
import RouteTab from 'components/molecules/routeTabs/routeTab/RouteTab';

function AdminPageShop() {
  const { t } = useTranslation();
  const { shopId } = useParams();
  const shop = useShop(shopId);

  return (
    <Dashboard>
      <DashboardHeadings
        headingText={t('views.admin.shop.heading', { shopName: shop && shop.name })}
        subheadingText={t('views.admin.shop.subheading', { shopName: shop && shop.name })}
      />

      {shop ? (
        <>
          <RouteTabsContainer>
            <RouteTab to="info" label={t('views.admin.shop.tabs.info')} />
            <RouteTab to="products" label={t('views.admin.shop.tabs.products')} />
            <RouteTab to="analytics" label={t('views.admin.shop.tabs.analytics')} />
            <RouteTab to="sessions" label={t('views.admin.shop.tabs.sessions')} />
            <RouteTab to="monitoring" label={t('views.admin.shop.tabs.monitoring')} />
            <RouteTab to="settings" label={t('views.admin.shop.tabs.settings')} />
          </RouteTabsContainer>

          <Outlet />
        </>
      ) : (
        <LayoutLoader height={400} />
      )}
    </Dashboard>
  );
}

export default AdminPageShop;
