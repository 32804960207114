import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useShop } from 'features/shops/hooks';

import styled from 'styled-components';
import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';

const Information = styled.div`
  display: flex;
  color: #616161;
  height: 35px;
`;
const InformationTitle = styled.div`
  margin-right: 20px;
  font-weight: bold;
`;
const InformationValue = styled.div``;

function AdminPageShopAnalytics() {
  const { t } = useTranslation();
  const { shopId } = useParams();
  const shop = useShop(shopId);

  return (
    <TabContentSection sectionTitle={t('views.admin.shop.analytics.heading')}>
      <Information>
        <InformationTitle>{t('views.admin.shop.analytics.sessions-count')}:</InformationTitle>
        <InformationValue>{shop.analytics.sessions_total}</InformationValue>
      </Information>
      <Information>
        <InformationTitle>{t('views.admin.shop.analytics.average-income')}:</InformationTitle>
        <InformationValue>{shop.analytics.income_average / 100} PLN</InformationValue>
      </Information>
      <Information>
        <InformationTitle>{t('views.admin.shop.analytics.average-products')}:</InformationTitle>
        <InformationValue>
          {shop.analytics.picked_products_average_number &&
            shop.analytics.picked_products_average_number.toFixed(1)}
        </InformationValue>
      </Information>
      <Information>
        <InformationTitle>{t('views.admin.shop.analytics.average-order')}:</InformationTitle>
        <InformationValue>{shop.analytics.duration_average} s</InformationValue>
      </Information>
    </TabContentSection>
  );
}

export default AdminPageShopAnalytics;
