import { createContext, useEffect, useState } from 'react';
import {
  isAuthenticated as getIsAuthenticated,
  getUser as getUserData,
  setAuthData,
  setCompany,
  setUser,
  removeAuthData,
} from 'features/app/utils/authentication';
import api from 'config/api';
import i18n from 'config/i18n';
import { fetchCurrentCompany } from 'features/currentCompany/thunks';
import { fetchCurrentUser } from 'features/currentUser/thunks';
import { useDispatch } from 'react-redux';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(getIsAuthenticated());
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchCurrentUser());
      dispatch(fetchCurrentCompany());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    const { locale } = getUserData();
    i18n.changeLanguage(locale);
  }, []);

  const signIn = async ({ payload, navigate }) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const { data } = await api.post('/authentications/authenticate', payload);
      setIsLoading(false);
      setAuthData(data.auth_token);
      setCompany(data.companies);
      setUser(data.user);
      setIsAuthenticated(getIsAuthenticated());
      navigate('/');
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const signOut = async ({ navigate }) => {
    removeAuthData();
    setIsAuthenticated(getIsAuthenticated());
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, isError, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
