import styled from 'styled-components';

const FlexCenterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export default FlexCenterContainer;
