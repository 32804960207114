import styled from 'styled-components';

const ResourcesPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 450px;
  background-size: contain;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  background-image: url(${({ $placeholderImage }) => $placeholderImage});
`;

export default ResourcesPlaceholder;
