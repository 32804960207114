import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveCalendar } from '@nivo/calendar';
import { fetchAnalytics } from 'features/analytics/thunks';

import Dashboard from 'components/organisms/layouts/dashboard/Dashboard';
import DashboardHeadings from 'components/molecules/dashboardHeadings/DashboardHeadings';
import FullWidth from 'components/atoms/containers/fullWidth/FullWidth';
import styled from 'styled-components';
import UserWidget from 'components/organisms/userWidget/UserWidget';
import SectionContainer from 'components/atoms/containers/sectionContainer/SectionContainer';

const AnalyticsContent = styled.div`
  width: calc(100% - 260px);

  ${SectionContainer} {
    height: 250px;
  }
`;

const UserContainerHolder = styled.div`
  position: fixed;
  right: 40px;
  top: 0px;
  padding: 30px 0px;
  z-index: 99;
`;

const ShopVisitsHolder = styled.div`
  height: 170px;

  h3 {
    padding-left: 20px;
    color: #818181;
  }
`;

function AdminPageGeneral() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const analytics = useSelector((state) => state.analytics);

  useEffect(() => {
    dispatch(fetchAnalytics());
  }, [dispatch]);

  return (
    <Dashboard>
      <AnalyticsContent>
        <DashboardHeadings
          headingText={t('views.admin.general.heading')}
          subheadingText={t('views.admin.general.subheading')}
          multipleCol={true}
        />
      </AnalyticsContent>
      <FullWidth>
        <AnalyticsContent>
          <SectionContainer>
            <ShopVisitsHolder>
              <h3>{t('views.admin.general.shop_visits')}</h3>
              {analytics.visits_graph && (
                <ResponsiveCalendar
                  data={analytics.visits_graph}
                  from="2024-01-01"
                  to="2024-12-31"
                  emptyColor="#eeeeee"
                  colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
                  margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                  yearSpacing={40}
                  monthBorderColor="#ffffff"
                  dayBorderWidth={2}
                  dayBorderColor="#ffffff"
                  legends={[
                    {
                      anchor: 'bottom-right',
                      direction: 'row',
                      translateY: 36,
                      itemCount: 4,
                      itemWidth: 42,
                      itemHeight: 36,
                      itemsSpacing: 14,
                      itemDirection: 'right-to-left',
                    },
                  ]}
                />
              )}
            </ShopVisitsHolder>
          </SectionContainer>
        </AnalyticsContent>
        <UserContainerHolder>
          <UserWidget />
        </UserContainerHolder>
      </FullWidth>
    </Dashboard>
  );
}

export default AdminPageGeneral;
