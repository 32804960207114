import styled from 'styled-components';
import ProductPlaceholder from 'assets/images/productPlaceholder.png';

const ProductPhotoPreview = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: url(${({ photo }) => (photo ? photo : ProductPlaceholder)});
  background-repeat: no-repeat;
  background-size: contain;
`;

export default ProductPhotoPreview;
