import { createSlice } from '@reduxjs/toolkit';
import { fetchCurrentCompany, updateCurrentCompany } from './thunks';

const initialState = {
  status: 'loading',
};

const currentCompanySlice = createSlice({
  name: 'currentCompany',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchCurrentCompany.fulfilled, (state, action) => {
      const { payload } = action;

      Object.assign(state, {
        ...payload,
        status: 'success',
      });
    });
    builder.addCase(updateCurrentCompany.pending, (state, action) => {
      Object.assign(state, {
        status: 'updating',
      });
    });
    builder.addCase(updateCurrentCompany.fulfilled, (state, action) => {
      const { payload } = action;

      Object.assign(state, {
        ...payload,
        status: 'success',
      });
    });
    builder.addCase(updateCurrentCompany.rejected, (state, action) => {
      Object.assign(state, {
        status: 'failure',
      });
    });
  },
});

export default currentCompanySlice.reducer;
