import axios from 'axios';
import { getAuthToken } from 'features/app/utils/authentication';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

api.interceptors.request.use(
  async function (config) {
    const { url } = config;
    const dontSetAuthHeaders = ['/authentications/authenticate'].includes(url);

    if (!dontSetAuthHeaders) {
      config.headers['Authorization'] = `Bearer: ${getAuthToken()}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default api;
