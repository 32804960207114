import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  width: 40px;
  height: 40px;
  display: flex;

  &:after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: ${({ theme }) => `${theme.colors.primary.color} transparent ${theme.colors.primary.color} transparent` };
    animation-name: ${loadingAnimation};
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;

export default Loader;
