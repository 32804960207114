import React from 'react';
import styled from 'styled-components';
import AuthContent from 'components/atoms/containers/authContent/AuthContent';
import FullWidth from 'components/atoms/containers/fullWidth/FullWidth';
import Logo from 'components/atoms/others/logo/Logo';
import TopShapeSvg from 'assets/images/shapes/top.svg';
import BottomShapeSvg from 'assets/images/shapes/bottom.svg';

const StyledLogo = styled(Logo)`
  margin-bottom: 40px;
  position: relative;
`;

const TopShape = styled.div`
  top: 0px;
  right: 0;
  width: 177px;
  height: 321px;
  position: fixed;
  background-image: url(${TopShapeSvg});
  background-size: contain;
`;

const BottomShape = styled.div`
  bottom: -2px;
  left: 0;
  width: 372px;
  height: 453px;
  position: fixed;
  background-image: url(${BottomShapeSvg});
  background-size: contain;
`;

function Auth({ children }) {
  return (
    <FullWidth>
      <TopShape />
      <BottomShape />
      <AuthContent>
        <StyledLogo type="default" />
        {children}
      </AuthContent>
    </FullWidth>
  );
}

export default Auth;
