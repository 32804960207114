import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShopSession } from 'features/shopSession/thunks';

export function useShopSession(shopId, rawShopSessionId) {
  const dispatch = useDispatch();
  const shopSession = useSelector((state) => state.shopSession);
  const shopSessionId = parseInt(rawShopSessionId, 10);
  const shopSessionIdFromStore = shopSession.data?.id;

  useEffect(() => {
    if (shopSessionId !== shopSessionIdFromStore && shopId && shopSessionId) {
      dispatch(fetchShopSession({ shopId, shopSessionId }));
    }
  }, [dispatch, shopSession, shopId, shopSessionId, shopSessionIdFromStore]);

  if (!shopSession || shopSessionId !== shopSessionIdFromStore) {
    return null;
  }

  return shopSession.data;
}
