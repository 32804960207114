import { useEffect } from 'react';
import Dashboard from 'components/organisms/layouts/dashboard/Dashboard';
import DashboardHeadings from 'components/molecules/dashboardHeadings/DashboardHeadings';
import EmptyResource from 'components/molecules/emptyResource/EmptyResource';
import FullSizeTable from 'components/molecules/table/fullSizeTable/FullSizeTable';
import LayoutLoader from 'components/molecules/loader/layout/LayoutLoader';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShops } from 'features/shops/thunks';
import { columns } from 'constants/tables/Shops';
import { useNavigate } from 'react-router-dom';

function AdminPageShops() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shops = useSelector((state) => state.shops);
  const translatedColumns = columns.map((column) => ({
    ...column,
    title: t(column['titleTranslationKey']),
  }));
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchShops());
  }, [dispatch]);

  return (
    <Dashboard>
      <DashboardHeadings
        headingText={t('views.admin.shops.heading')}
        subheadingText={t('views.admin.shops.subheading')}
      />

      {shops.status === 'loading' ? (
        <LayoutLoader height={400} />
      ) : shops.collection.length ? (
        <FullSizeTable
          data={shops.collection}
          columns={translatedColumns}
          rowEventHandlers={{
            onClick: ({ rowData }) => navigate(`/admin/shops/${rowData.id}/info`),
          }}
        />
      ) : (
        <EmptyResource resource="shops" />
      )}
    </Dashboard>
  );
}

export default AdminPageShops;
