import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyUuid } from 'features/app/utils/authentication';

import api from 'config/api';

export const fetchCurrentUser = createAsyncThunk('currentUser/fetchCurrentUser', async () => {
  const { data: currentUser } = await api.get(`/companies/${getCompanyUuid()}/current_user`);
  return currentUser;
});

export const updateCurrentUser = createAsyncThunk(
  'currentUser/updateCurrentUser',
  async (payload) => {
    const { data: currentUser } = await api.put(
      `/companies/${getCompanyUuid()}/current_user`,
      payload,
    );
    return currentUser;
  },
);
