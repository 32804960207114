import { createSlice } from '@reduxjs/toolkit';
import { fetchShops, fetchShop } from './thunks';

const initialState = {
  collection: [],
  status: 'idle',
};

const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchShops.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchShops.fulfilled, (state, action) => {
      const { payload } = action;

      state.collection = payload;
      state.status = 'success';
    });
    builder.addCase(fetchShop.fulfilled, (state, action) => {
      const { payload } = action;
      const shop = state.collection.find((s) => s.id === payload.id);

      if (!shop) {
        state.collection = [...state.collection, payload];
      }
    });
  },
});

export default shopsSlice.reducer;
