import { createSlice } from '@reduxjs/toolkit';
import { fetchAnalytics } from './thunks';

const initialState = {
  visits_graph: [],
  status: 'loading',
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAnalytics.fulfilled, (state, action) => {
      const { payload } = action;

      const formattedVisitsGraph = payload.map((item) => ({
        value: item.customer_sessions_count,
        day: item.date,
      }));

      state.visits_graph = formattedVisitsGraph;
      state.status = 'success';
    });
  },
});

export default analyticsSlice.reducer;
