import styled from 'styled-components';
import Loader from 'components/atoms/others/loader/Loader';

const StyledLoader = styled(Loader)`
  width: 18px;
  height: 18px;
  margin: 0 auto;

  &:after {
    width: 18px;
    height: 18px;
    border-width: 2px;
    border-color: #ffffff transparent #ffffff transparent;
  }
`;

const StyledButton = styled.button`
  padding: 14px;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.colors.tertiary.color};
  background-color: ${({ theme }) => theme.colors.tertiary.background};
  border: 2px solid ${({ theme }) => theme.colors.tertiary.background};
  border-radius: ${({ theme }) => theme.utils.borderRadius}px;
  transition: All ${({ theme }) => theme.utils.transition};
  cursor: pointer;
  outline: none;
  float: ${({ float }) => (float ? float : 'auto')};

  &:hover {
    color: ${({ theme }) => theme.colors.tertiary.hover};
  }
`;

const Button = ({ isLoading, ...props }) => {
  if (isLoading) {
    return (
      <StyledButton {...props}>
        <StyledLoader />
      </StyledButton>
    );
  }

  return <StyledButton {...props} />;
};

Button.Styled = StyledButton;

export default Button;
