import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyUuid } from 'features/app/utils/authentication';

import api from 'config/api';

export const fetchShopSessions = createAsyncThunk(
  'shopSessions/fetchShopSessions',
  async (payload) => {
    const { data: shopSessions } = await api.get(
      `/companies/${getCompanyUuid()}/shops/${payload.shopId}/shop_sessions`,
    );
    return shopSessions;
  },
);
