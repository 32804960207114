import { DevicesMinWidth } from 'constants/Devices';

import styled from 'styled-components';

const Footer = styled.div`
  width: ${({ theme }) => theme.sidebar.width};
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.tertiary.color};
  font-size: ${({ theme }) => theme.fontSize.xsmall};

  @media ${DevicesMinWidth.laptop} {
    display: flex;
  }
`;

export default Footer;
