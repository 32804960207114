import { createSlice } from '@reduxjs/toolkit';
import { fetchCurrentUser, updateCurrentUser } from './thunks';

const initialState = {
  status: 'loading',
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      const { payload } = action;

      Object.assign(state, {
        ...payload,
        status: 'success',
      });
    });
    builder.addCase(updateCurrentUser.pending, (state, action) => {
      Object.assign(state, {
        status: 'updating',
      });
    });
    builder.addCase(updateCurrentUser.fulfilled, (state, action) => {
      const { payload } = action;

      Object.assign(state, {
        ...payload,
        status: 'success',
      });
    });
    builder.addCase(updateCurrentUser.rejected, (state, action) => {
      Object.assign(state, {
        status: 'failure',
      });
    });
  },
});

export default currentUserSlice.reducer;
