import { DevicesMinWidth } from 'constants/Devices';
import { ImHome, ImStack, ImPriceTags, ImClipboard, ImEqualizer, ImUsers } from 'react-icons/im';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

const MenuItemHolder = styled(NavLink)`
  position: relative;
  display: flex;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.small};
  height: 50px;
  line-height: 50px;
  background-color: ${({ theme }) => theme.colors.primary.color};
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary.color};
  padding-left: 20px;
  margin-bottom: 15px;
  transition: ${({ theme }) => theme.utils.transition} color;

  & svg {
    margin-top: 18px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.tertiary.hover} !important;
  }

  &.active {
    color: ${({ theme }) => theme.colors.primary.hover} !important;
    background-color: #fff;
    border-radius: 25px;
  }
`;

const IconHolder = styled.div`
  margin-right: 20px;
`;

const TextHolder = styled.span`
  display: none;

  @media ${DevicesMinWidth.laptop} {
    display: flex;
  }
`;

function renderIcon(name) {
  switch (name) {
    case 'home':
      return <ImHome />;
    case 'shops':
      return <ImStack />;
    case 'users':
      return <ImUsers />;
    case 'products':
      return <ImPriceTags />;
    case 'health':
      return <ImClipboard />;
    case 'settings':
      return <ImEqualizer />;
    default:
      return <ImHome />;
  }
}

function MenuItem({ name, iconName, path, className }) {
  return (
    <MenuItemHolder to={path}>
      <IconHolder>{renderIcon(iconName)}</IconHolder>
      <TextHolder>{name}</TextHolder>
    </MenuItemHolder>
  );
}

export default MenuItem;
