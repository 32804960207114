import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import BaseTable, { AutoResizer } from 'react-base-table';
import 'react-base-table/styles.css';

const TableHolder = styled.div`
  width: 100%;
  height: calc(100vh - 230px);

  .BaseTable__row {
    cursor: pointer;
  }
`;

const EmptyPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
`;

function FullSizeTable({ columns, data, rowEventHandlers = {}, emptyText }) {
  const { t } = useTranslation();
  const emptyRenderer = useMemo(() => {
    if (emptyText) {
      return () => <EmptyPlaceholder>{emptyText}</EmptyPlaceholder>;
    }

    return () => <EmptyPlaceholder>{t('common.no-data')}</EmptyPlaceholder>;
  }, [t, emptyText]);

  if (!data) {
    data = [];
  }

  return (
    <TableHolder>
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            width={width}
            height={height}
            columns={columns}
            data={data}
            rowEventHandlers={rowEventHandlers}
            emptyRenderer={emptyRenderer}
          />
        )}
      </AutoResizer>
    </TableHolder>
  );
}

export default FullSizeTable;
