import { createSlice } from '@reduxjs/toolkit';
import { fetchProducts, fetchProduct, updateProduct } from './thunks';

const initialState = {
  collection: [],
  status: 'loading',
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      const { payload } = action;
      state.collection = payload;
      state.status = 'success';
    });
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      const { payload } = action;
      const product = state.collection.find((product) => product.id === payload.id);

      if (!product) {
        state.collection = [...state.collection, payload];
      }
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      const { payload } = action;
      const product = state.collection.find((product) => product.id === payload.id);
      Object.assign(product, payload);
    });
  },
});

export default productsSlice.reducer;
