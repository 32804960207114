import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import MainTheme from 'theme/MainTheme';
import Button from 'components/atoms/others/button/Button';
import { FaTimes } from 'react-icons/fa';

const styles = ({ maxWidth }) => ({
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  content: {
    width: '100%',
    maxWidth: maxWidth || 600,
    maxHeight: 'calc(100vh - 40px)',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    padding: 0,
    inset: 'auto',
    border: 'none',
    borderRadius: MainTheme.utils.borderRadius,
    boxShadow: MainTheme.shadows.base,
  },
});

const Header = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.utils.space}px;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
`;

const HeaderCloseIcon = styled.div`
  display: flex;
  margin-left: auto;
  padding: ${({ theme }) => theme.utils.space}px;
  cursor: pointer;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.utils.space}px;
`;

const SubmitButton = styled(Button)`
  margin-left: auto;
`;

Modal.setAppElement('#root');

function RegularModal({ onSubmit, onClose, children, header, submitText, maxWidth }) {
  return (
    <Modal isOpen={true} onRequestClose={onClose} style={{ ...styles({ maxWidth }) }}>
      <Header>
        {header}
        <HeaderCloseIcon onClick={onClose}>
          <FaTimes size={'1.4em'} color={MainTheme.colors.secondary.color} />
        </HeaderCloseIcon>
      </Header>

      {children}

      <ButtonsWrapper>
        {submitText && <SubmitButton onClick={() => onSubmit()}>{submitText}</SubmitButton>}
      </ButtonsWrapper>
    </Modal>
  );
}

export default RegularModal;
