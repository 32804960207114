import React from 'react';
import ReactDOM from 'react-dom';
import store from 'config/store';
import MainTheme from 'theme/MainTheme';
import GlobalStyle from 'theme/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { Routes as routes } from 'constants/Routes';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import WebFont from 'webfontloader';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './components/PrivateRoute';
import SignInPage from 'views/SignInPage';
import AdminPageGeneral from 'views/admin/AdminPageGeneral';
import AdminPageShops from 'views/admin/AdminPageShops';
import AdminPageShop from 'views/admin/AdminPageShop';
import AdminPageShopInfo from 'views/admin/AdminPageShopInfo';
import AdminPageShopProducts from 'views/admin/AdminPageShopProducts';
import AdminPageShopAnalytics from 'views/admin/AdminPageShopAnalytics';
import AdminPageShopSessions from 'views/admin/AdminPageShopSessions';
import AdminPageShopMonitoring from 'views/admin/AdminPageShopMonitoring';
import AdminPageShopSettings from 'views/admin/AdminPageShopSettings';
import AdminPageUsers from 'views/admin/AdminPageUsers';
import AdminPageProducts from 'views/admin/AdminPageProducts';
import AdminPageShowProduct from 'views/admin/AdminPageShowProduct';
import AdminPageNewProduct from 'views/admin/AdminPageNewProduct';
import AdminPageSettings from 'views/admin/AdminPageSettings';
import AdminPageShopSession from 'views/admin/AdminPageShopSession';
import AdminPageShopSessionInfo from 'views/admin/AdminPageShopSessionInfo';
import AdminPageShopSessionVideos from 'views/admin/AdminPageShopSessionVideos';
import AdminPageShopSessionVideo from 'views/admin/AdminPageShopSessionVideo';
import AdminPageShopSessionLogs from 'views/admin/AdminPageShopSessionLogs';
import AdminPageShopSessionProducts from 'views/admin/AdminPageShopSessionProducts';
import AdminPageShopSessionReceipt from 'views/admin/AdminPageShopSessionReceipt';
import AdminPageProfile from 'views/admin/AdminPageProfile';
import './config/i18n';
import { AuthProvider } from 'features/app/contexts/authentication';

import 'react-toastify/dist/ReactToastify.css';

WebFont.load({
  google: {
    families: ['Open Sans:300,400,600,700', 'sans-serif'],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ToastContainer autoClose={2000} />
    <ThemeProvider theme={MainTheme}>
      <Provider store={store}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path={routes.ADMIN} element={<PrivateRoute />}>
                <Route path={routes.ADMIN_SHOPS}>
                  <Route index element={<AdminPageShops />} />
                  <Route path={routes.ADMIN_SHOP} element={<AdminPageShop />}>
                    <Route path={routes.ADMIN_SHOP_INFO} element={<AdminPageShopInfo />} />
                    <Route path={routes.ADMIN_SHOP_PRODUCTS} element={<AdminPageShopProducts />} />
                    <Route
                      path={routes.ADMIN_SHOP_ANALYTICS}
                      element={<AdminPageShopAnalytics />}
                    />
                    <Route
                      path={routes.ADMIN_SHOP_MONITORING}
                      element={<AdminPageShopMonitoring />}
                    />
                    <Route path={routes.ADMIN_SHOP_SETTINGS} element={<AdminPageShopSettings />} />
                    <Route path={routes.ADMIN_SHOP_SESSIONS} element={<AdminPageShopSessions />} />
                  </Route>
                </Route>
                <Route path={routes.ADMIN_SHOP_SESSIONS}>
                  <Route path={routes.ADMIN_SHOP_SESSION} element={<AdminPageShopSession />}>
                    <Route
                      path={routes.ADMIN_SHOP_SESSION_INFO}
                      element={<AdminPageShopSessionInfo />}
                    />
                    <Route
                      path={routes.ADMIN_SHOP_SESSION_VIDEOS}
                      element={<AdminPageShopSessionVideos />}
                    >
                      <Route
                        path={routes.ADMIN_SHOP_SESSION_VIDEO}
                        element={<AdminPageShopSessionVideo />}
                      />
                    </Route>
                    <Route
                      path={routes.ADMIN_SHOP_SESSION_LOGS}
                      element={<AdminPageShopSessionLogs />}
                    />
                    <Route
                      path={routes.ADMIN_SHOP_SESSION_PURCHASED_PRODUCTS}
                      element={<AdminPageShopSessionProducts />}
                    />
                    <Route
                      path={routes.ADMIN_SHOP_SESSION_RECEIPT}
                      element={<AdminPageShopSessionReceipt />}
                    />
                  </Route>
                </Route>
                <Route path={routes.ADMIN_PRODUCTS} element={<AdminPageProducts />}>
                  <Route path={routes.ADMIN_PRODUCTS_SHOW} element={<AdminPageShowProduct />} />
                  <Route path={routes.ADMIN_PRODUCTS_NEW} element={<AdminPageNewProduct />} />
                </Route>
                <Route path={routes.ADMIN_USERS} element={<AdminPageUsers />} />
                <Route path={routes.ADMIN_SETTINGS} element={<AdminPageSettings />} />
                <Route path={routes.ADMIN_PROFILE} element={<AdminPageProfile />} />
                <Route path={routes.ADMIN_HOME} element={<AdminPageGeneral />} />
              </Route>
              <Route path={routes.SIGN_IN} element={<SignInPage />} />
              <Route path="/" element={<Navigate to={routes.ADMIN_HOME} />} />
            </Routes>
          </Router>
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
