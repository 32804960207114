import { createSlice } from '@reduxjs/toolkit';
import { fetchShopSession } from './thunks';

const initialState = {
  data: null,
  status: 'idle',
};

const shopSessionSlice = createSlice({
  name: 'shopSession',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchShopSession.fulfilled, (state, action) => {
      const { payload } = action;

      state.data = payload;
      state.status = 'success';
    });
  },
});

export default shopSessionSlice.reducer;
