import styled from 'styled-components';

const FormFieldHolder = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${({ $spaceAround, theme }) => ($spaceAround ? theme.utils.space : 0)}px;
  padding-right: ${({ $spaceAround, theme }) => ($spaceAround ? theme.utils.space : 0)}px;
  margin-top: ${({ $marginTop, theme }) => ($marginTop ? theme.utils.space : 0)}px;
`;

const Label = styled.span`
  margin-bottom: ${({ theme }) => theme.utils.space / 2}px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

function FormField({ label, children, spaceAround, marginTop }) {
  return (
    <FormFieldHolder $spaceAround={spaceAround} $marginTop={marginTop}>
      <Label>{label}</Label>
      {children}
    </FormFieldHolder>
  );
}

export default FormField;
