import StatusIcon from 'components/atoms/others/statusIcon/StatusIcon';

export const columns = [
  {
    key: 'name',
    dataKey: 'name',
    titleTranslationKey: 'views.admin.shop.info.health.columns.service-name',
    width: 800,
  },
  {
    key: 'status',
    dataKey: 'status',
    titleTranslationKey: 'views.admin.shop.info.health.columns.status',
    align: 'center',
    cellRenderer: ({ cellData: status }) => <StatusIcon status={status} />,
    width: 100,
  },
  {
    key: 'type',
    dataKey: 'type',
    align: 'center',
    titleTranslationKey: 'views.admin.shop.info.health.columns.type',
    width: 200,
  },
  {
    key: 'updatedAt',
    dataKey: 'updatedAt',
    titleTranslationKey: 'views.admin.shop.info.health.columns.last-checked-at',
    width: 200,
  },
];
