import { configureStore } from '@reduxjs/toolkit';
import shopsReducer from 'features/shops';
import shopSessionReducer from 'features/shopSession';
import shopSessionsReducer from 'features/shopSessions';
import productsReducer from 'features/products';
import currentUserReducer from 'features/currentUser';
import currentCompanyReducer from 'features/currentCompany';
import dashbaordReducer from 'features/dashboard';
import analyticsReducer from 'features/analytics';

const store = configureStore({
  reducer: {
    shops: shopsReducer,
    products: productsReducer,
    currentUser: currentUserReducer,
    currentCompany: currentCompanyReducer,
    dashboard: dashbaordReducer,
    analytics: analyticsReducer,
    shopSession: shopSessionReducer,
    shopSessions: shopSessionsReducer,
  },
});

export default store;
