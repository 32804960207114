import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { fetchShopSessions } from 'features/shopSessions/thunks';

import styled from 'styled-components';
import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';
import FullSizeTable from 'components/molecules/table/fullSizeTable/FullSizeTable';
import LayoutLoader from 'components/molecules/loader/layout/LayoutLoader';

const CorruptedText = styled.span`
  color: ${({ theme }) => theme.colors.failure.color};
`;

const columns = [
  {
    key: 'id',
    dataKey: 'id',
    titleTranslationKey: 'views.admin.shop.sessions.columns.id',
    width: 100,
  },
  {
    key: 'user_first_name',
    dataKey: 'user_first_name',
    titleTranslationKey: 'views.admin.shop.sessions.columns.user-first-name',
    width: 200,
  },
  {
    key: 'user_last_name',
    dataKey: 'user_last_name',
    titleTranslationKey: 'views.admin.shop.sessions.columns.user-last-name',
    width: 200,
  },
  {
    key: 'created_at',
    titleTranslationKey: 'views.admin.shop.sessions.columns.date',
    width: 400,
    dataGetter: ({ rowData }) => {
      return format(new Date(rowData.created_at), 'dd.MM.yyyy HH:mm');
    },
  },
  {
    key: 'total_gross',
    dataKey: 'total_gross',
    titleTranslationKey: 'views.admin.shop.sessions.columns.total',
    width: 200,
  },
  {
    key: 'type',
    titleTranslationKey: 'common.sessions.type',
    width: 200,
    dataGetter: ({ rowData }, t) => {
      return rowData.data.user.role === 'regular_shopping' ? t('common.client') : t('common.admin');
    },
  },
  {
    key: 'state',
    titleTranslationKey: 'common.sessions.state',
    width: 250,
    dataGetter: ({ rowData }, t) => {
      switch (rowData.data.user.state) {
        case 'corrupted': {
          return <CorruptedText>{t('common.sessions.corrupted')} / Corrupted</CorruptedText>;
        }
        case 'completed': {
          return t('common.sessions.completed');
        }
        default: {
          return rowData.data.user.state;
        }
      }
    },
  },
];

function AdminPageShopSessions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { shopId } = useParams();
  const dispatch = useDispatch();
  const shopSessionsState = useSelector((state) => state.shopSessions);
  const shopSessions = useMemo(() => {
    return shopSessionsState.collection;
  }, [shopSessionsState.collection]);
  const translatedColumns = columns.map((column) => ({
    ...column,
    title: t(column['titleTranslationKey']),
    ...(column.dataGetter && { dataGetter: (data) => column.dataGetter(data, t) }),
  }));

  useEffect(() => {
    dispatch(fetchShopSessions({ shopId }));
  }, [dispatch, shopId]);

  return (
    <TabContentSection sectionTitle={t('views.admin.shop.sessions.heading')}>
      {shopSessionsState.status === 'loading' ? (
        <LayoutLoader height={400} />
      ) : (
        <FullSizeTable
          data={shopSessions}
          columns={translatedColumns}
          rowEventHandlers={{
            onClick: ({ rowData }) =>
              navigate(`/admin/shops/${shopId}/sessions/${rowData.id}/info`),
          }}
        />
      )}
    </TabContentSection>
  );
}

export default AdminPageShopSessions;
