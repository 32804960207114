import React from 'react';
import styled from 'styled-components';
import Heading from 'components/atoms/texts/heading/Heading';

const StyledStatisticsContainer = styled.div`
  width: 100%;
  height: 70px;
  margin-top: 20px;
  box-shadow: 5px 5px 5px #dedada;
  border-radius: 10px;
  border: 1px solid #eae7e7;
  box-sizing: content-box;
  background-color: #ffffff6b;

  ${Heading} {
    text-align: left !important;
    line-height: 30px;
    margin-top: 10px;
    font-size: 2rem;
  }
`;

const IconHolder = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
  margin-right: 20px;
  border-radius: 10px;
  font-size: 2em;
  color: #05c798;
  text-align: center;
  line-height: 80px;
  float: left;
`;

const TileDescription = styled.div`
  margin-top: -15px;
  opacity: 0.5;
  font-size: 1.1rem;
`;

function AccountStatisticWidget({ name, count, children }) {
  return (
    <StyledStatisticsContainer>
      <IconHolder>{children}</IconHolder>
      <Heading $colorType="secondary">{count}</Heading>
      <TileDescription>{name}</TileDescription>
    </StyledStatisticsContainer>
  );
}

export default AccountStatisticWidget;
