import styled from 'styled-components';
import Button from 'components/atoms/others/button/Button';
import { Link } from 'react-router-dom';

const StyledLinkButton = styled(Button.Styled).attrs({
  as: 'span',
})``;

function LinkButton({ path, text }) {
  return (
    <Link to={path}>
      <StyledLinkButton path={path}>{text}</StyledLinkButton>
    </Link>
  );
}

export default LinkButton;
