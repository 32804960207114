import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  padding: 14px;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  border: 1px solid #c7c8ca;
  border-radius: ${({ theme }) => theme.utils.borderRadius}px;
  outline: none;
  transition: All ${({ theme }) => theme.utils.transition};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.color};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary.color};
  }
`;

function Input({ type, placeholder, value, onChange, className }) {
  const inputType = type || 'text';

  return (
    <StyledInput
      className={className}
      type={inputType}
      placeholder={placeholder}
      value={value || ''}
      onChange={(event) => onChange(event.target.value)}
    />
  );
}

export default Input;
