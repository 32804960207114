import { DevicesMinWidth } from 'constants/Devices';

import styled from 'styled-components';

const Sidebar = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.background};
  width: ${({ theme }) => theme.sidebar.mobileWidth};
  height: 100vh;
  position: fixed;
  z-index: 1;

  @media ${DevicesMinWidth.laptop} {
    width: ${({ theme }) => theme.sidebar.width};
    display: flex;
  }
`;

export default Sidebar;
