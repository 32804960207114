import { DevicesMinWidth } from 'constants/Devices';

import styled from 'styled-components';
import Logo from 'components/atoms/others/logo/Logo';
import MenuList from 'components/molecules/menu/menuList/MenuList';
import Footer from 'components/atoms/texts/footer/Footer';

const SidebarMenuHolder = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${Logo} {
    display: none;

    @media ${DevicesMinWidth.laptop} {
      display: flex;
    }
  }
`;

const StyledLogo = styled(Logo)`
  margin: 60px 40px;
`;

function SidebarMenu() {
  return (
    <SidebarMenuHolder>
      <StyledLogo />
      <MenuList />
      <Footer>© {new Date().getFullYear()} ShopBox</Footer>
    </SidebarMenuHolder>
  );
}

export default SidebarMenu;
