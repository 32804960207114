import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentCompany, updateCurrentCompany } from 'features/currentCompany/thunks';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import styled from 'styled-components';
import Dashboard from 'components/organisms/layouts/dashboard/Dashboard';
import DashboardHeadings from 'components/molecules/dashboardHeadings/DashboardHeadings';
import Button from 'components/atoms/others/button/Button';
import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';
import FormField from 'components/molecules/forms/formField/FormField';
import Input from 'components/atoms/others/input/Input';
import ImageEditor from 'components/molecules/imageEditor/ImageEditor';

const SettingsCompanyContainer = styled.div`
  ${Button.Styled} {
    margin-top: ${({ theme }) => theme.utils.space}px;
  }
`;

function AdminPageSettings() {
  const { t } = useTranslation();
  const company = useSelector((state) => state.currentCompany);
  const dispatch = useDispatch();

  const [logo, setLogo] = useState();

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [krs, setKrs] = useState('');
  const [nip, setNip] = useState('');
  const [regon, setRegon] = useState('');

  useEffect(() => {
    if (company) {
      setLogo(company.logo);
      setName(company.name);
      setAddress(company.address);
      setKrs(company.krs);
      setNip(company.nip);
      setRegon(company.regon);
    }
  }, [company]);

  useEffect(() => {
    dispatch(fetchCurrentCompany());
  }, [dispatch]);

  const onLogoSave = useCallback((logoData) => {
    setLogo(logoData);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    await dispatch(updateCurrentCompany({ id: company.id, name, address, krs, nip, regon, logo }));

    toast(t('notifications.updating_company'));
  };

  return (
    <Dashboard>
      <DashboardHeadings
        headingText={t('views.admin.settings.heading')}
        subheadingText={t('views.admin.settings.subheading')}
      />

      <SettingsCompanyContainer>
        <TabContentSection>
          <form onSubmit={handleSubmit}>
            <FormField label={t('views.admin.settings.logo.base')}>
              <ImageEditor
                image={logo}
                onSave={onLogoSave}
                imageNoun={t('views.admin.settings.logo.image-noun')}
                emptyLabel={t('views.admin.settings.logo.empty-info')}
              />
            </FormField>
            <FormField marginTop label={t('views.admin.settings.name')}>
              <Input value={name} onChange={setName} />
            </FormField>
            <FormField marginTop label={t('views.admin.settings.address')}>
              <Input value={address} onChange={setAddress} />
            </FormField>
            <FormField marginTop label={t('views.admin.settings.krs')}>
              <Input value={krs} onChange={setKrs} />
            </FormField>
            <FormField marginTop label={t('views.admin.settings.nip')}>
              <Input value={nip} onChange={setNip} />
            </FormField>
            <FormField marginTop label={t('views.admin.settings.regon')}>
              <Input value={regon} onChange={setRegon} />
            </FormField>
            <Button type="submit" isLoading={company.status === 'updating'}>
              {t('common.save')}
            </Button>
          </form>
        </TabContentSection>
      </SettingsCompanyContainer>
    </Dashboard>
  );
}

export default AdminPageSettings;
