import styled from 'styled-components';

const SectionContainer = styled.div`
  width: 90%;
  height: 100%;
  border: 1px solid #d0d0d036;
  border-radius: 10px;
  box-shadow: 0 0 5px #0000001f;
`;

export default SectionContainer;
