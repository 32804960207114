import styled from 'styled-components';

const AuthContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.primary.background};
`;

export default AuthContent;
