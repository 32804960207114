import { DevicesMinWidth } from 'constants/Devices';

import styled from 'styled-components';

const UserContainer = styled.div`
  width: ${({ theme }) => theme.sidebar.mobileWidth};
  height: calc(100vh - 90px);
  border-radius: ${({ theme }) => theme.utils.borderRadius}px;
  background-color: ${({ theme }) => theme.colors.detail.background};
  padding: 40px 20px;
  box-shadow: 5px 5px 5px ${({ theme }) => theme.colors.detail.shadow};
  overflow: auto;

  @media ${DevicesMinWidth.laptop} {
    width: ${({ theme }) => theme.sidebar.width};
  }
`;

export default UserContainer;
