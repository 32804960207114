import React from 'react';
import styled from 'styled-components';
import Loader from 'components/atoms/others/loader/Loader';

const LayoutLoaderHolder = styled.div`
  width: 100%;
  height: ${props => props.height !== undefined ? `${props.height}px` : '100%' };
  display: flex;
  align-items: center;
  justify-content: center;
`;

function LayoutLoader({ height }) {
  return (
    <LayoutLoaderHolder height={height}>
      <Loader />
    </LayoutLoaderHolder>
  );
}

export default LayoutLoader;
