import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyUuid } from 'features/app/utils/authentication';

import api from 'config/api';

export const fetchShops = createAsyncThunk('shops/fetchShops', async () => {
  const { data: shops } = await api.get(`/companies/${getCompanyUuid()}/shops`);
  return shops;
});

export const fetchShop = createAsyncThunk('shops/fetchShop', async (payload) => {
  const { data: shop } = await api.get(`/companies/${getCompanyUuid()}/shops/${payload.id}`);
  return shop;
});
