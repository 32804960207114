import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useShopSession } from 'features/shopSession/hooks';

import Dashboard from 'components/organisms/layouts/dashboard/Dashboard';
import DashboardHeadings from 'components/molecules/dashboardHeadings/DashboardHeadings';
import LayoutLoader from 'components/molecules/loader/layout/LayoutLoader';
import RouteTabsContainer from 'components/molecules/routeTabs/routeTabsContainer/RouteTabsContiner';
import RouteTab from 'components/molecules/routeTabs/routeTab/RouteTab';

function AdminPageShopSession() {
  const { t } = useTranslation();
  const { shopId, shopSessionId } = useParams();
  const shopSession = useShopSession(shopId, shopSessionId);

  return (
    <Dashboard>
      <DashboardHeadings
        headingText={t('views.admin.shop-session.heading', {
          uuid: shopSession && shopSession.uuid,
        })}
        subheadingText={shopSession && format(new Date(shopSession.created_at), 'dd.MM.yyyy HH:mm')}
      />

      {shopSession ? (
        <>
          <RouteTabsContainer>
            <RouteTab to="info" label={t('views.admin.shop-session.tabs.info')} />
            <RouteTab to="videos" label={t('views.admin.shop-session.tabs.videos')} />
            <RouteTab to="logs" label={t('views.admin.shop-session.tabs.logs')} />
            <RouteTab
              to="purchased-products"
              label={
                shopSession?.data?.user?.role === 'regular_shopping'
                  ? t('views.admin.shop-session.tabs.products')
                  : t('views.admin.shop-session.tabs.loaded-products')
              }
            />
            {shopSession?.data?.user?.role === 'regular_shopping' && (
              <RouteTab to="receipt" label={t('views.admin.shop-session.tabs.receipt')} />
            )}
          </RouteTabsContainer>

          <Outlet />
        </>
      ) : (
        <LayoutLoader height={400} />
      )}
    </Dashboard>
  );
}

export default AdminPageShopSession;
