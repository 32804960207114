import { useTranslation } from 'react-i18next';

import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';

function AdminPageShopSettings() {
  const { t } = useTranslation();

  return (
    <TabContentSection sectionTitle={t('views.admin.shop.settings.heading')}>
      <div>Lock/unlock shop</div>
      <div>Force sync procucts</div>
      <div>etc</div>
    </TabContentSection>
  );
}

export default AdminPageShopSettings;
