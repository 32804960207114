import styled from 'styled-components';
import AvatarPlaceholder from 'assets/images/avatarPlaceholder.png';

const UserImage = styled.div`
  width: ${(props) => (props.size !== undefined ? `${props.size}` : 140)}px;
  height: ${(props) => (props.size !== undefined ? `${props.size}` : 140)}px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ avatar }) =>
    !avatar || avatar === 'data:image/png;base64,' ? AvatarPlaceholder : avatar});
  border-radius: 20px;
`;

export default UserImage;
