import { useEffect, useState } from 'react';
import { columns } from 'constants/tables/Health';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useShop } from 'features/shops/hooks';
import { format } from 'date-fns';

import styled from 'styled-components';
import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';
import FullSizeTable from 'components/molecules/table/fullSizeTable/FullSizeTable';

const Information = styled.div`
  display: flex;
  color: #616161;
  height: 35px;
`;
const InformationTitle = styled.div`
  margin-right: 20px;
  font-weight: bold;
`;
const InformationValue = styled.div``;

function AdminPageShopInfo() {
  const { t } = useTranslation();
  const { shopId } = useParams();
  const shop = useShop(shopId);

  const translatedColumns = columns.map((column) => ({
    ...column,
    title: t(column['titleTranslationKey']),
  }));

  const [healthData, setHealthData] = useState();

  const fetchServiceHealthData = () => {
    const globalApiShopId = 1;
    fetch(`${process.env.REACT_APP_GLOBAL_API_HOST}/health_data/${globalApiShopId}`)
      .then((response) => response.json())
      .then((jsonData) => {
        setHealthData(Object.keys(jsonData).map((key) => jsonData[key]));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchServiceHealthData();
    const interval = setInterval(fetchServiceHealthData, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <TabContentSection sectionTitle={t('views.admin.shop.info.heading')}>
        <Information>
          <InformationTitle>{t('views.admin.shop.info.name')}:</InformationTitle>
          <InformationValue>{shop.name}</InformationValue>
        </Information>
        <Information>
          <InformationTitle>{t('views.admin.shop.info.last-updated-at')}:</InformationTitle>
          <InformationValue>
            {format(new Date(shop.updated_at), 'dd.MM.yyyy HH:mm')}
          </InformationValue>
        </Information>
      </TabContentSection>

      <TabContentSection sectionTitle={t('views.admin.shop.info.services-state')}>
        <FullSizeTable data={healthData} columns={translatedColumns} />
      </TabContentSection>
    </>
  );
}

export default AdminPageShopInfo;
