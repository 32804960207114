export const columns = [
  {
    key: 'name',
    dataKey: 'product.name',
    titleTranslationKey: 'views.admin.shop-products.columns.product-name',
    width: 400,
  },
  {
    key: 'quantity',
    dataKey: 'quantity',
    titleTranslationKey: 'views.admin.shop-products.columns.quantity',
    width: 400,
  },
  {
    key: 'price',
    dataKey: 'price',
    titleTranslationKey: 'views.admin.shop-products.columns.price',
    width: 400,
  },
  {
    key: 'updated_at',
    dataKey: 'updated_at',
    titleTranslationKey: 'views.admin.shop-products.columns.updated-at',
    width: 400,
  },
];
