import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useShopSession } from 'features/shopSession/hooks';
import { format } from 'date-fns';

import styled from 'styled-components';
import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';

const Information = styled.div`
  display: flex;
  color: #616161;
  height: 35px;
`;
const InformationTitle = styled.div`
  margin-right: 20px;
  font-weight: bold;
`;
const InformationValue = styled.div``;

const CorruptedText = styled.span`
  color: ${({ theme }) => theme.colors.failure.color};
`;

function AdminPageShopSessionInfo() {
  const { t } = useTranslation();
  const { shopId, shopSessionId } = useParams();
  const shopSession = useShopSession(shopId, shopSessionId);

  const shopSessionState = useMemo(() => {
    if (!shopSession) {
      return null;
    }

    switch (shopSession.data.user.state) {
      case 'corrupted': {
        return <CorruptedText>{t('common.sessions.corrupted')} / Corrupted</CorruptedText>;
      }
      case 'completed': {
        return t('common.sessions.completed');
      }
      default: {
        return shopSession.data.user.state;
      }
    }
  }, [shopSession, t]);

  if (!shopSession) {
    return null;
  }

  const type =
    shopSession.data.user.role === 'regular_shopping' ? t('common.client') : t('common.admin');

  return (
    <TabContentSection sectionTitle={t('views.admin.shop-session.info.heading')}>
      <Information>
        <InformationTitle>Id:</InformationTitle>
        <InformationValue>{shopSession.id}</InformationValue>
      </Information>
      <Information>
        <InformationTitle>Uuid:</InformationTitle>
        <InformationValue>{shopSession.uuid}</InformationValue>
      </Information>
      <Information>
        <InformationTitle>{t('common.sessions.type')}:</InformationTitle>
        <InformationValue>{type}</InformationValue>
      </Information>
      <Information>
        <InformationTitle>{t('common.sessions.state')}:</InformationTitle>
        <InformationValue>{shopSessionState}</InformationValue>
      </Information>
      <Information>
        <InformationTitle>{t('views.admin.shop-session.info.date')}:</InformationTitle>
        <InformationValue>
          {format(new Date(shopSession.created_at), 'dd.MM.yyyy HH:mm')}
        </InformationValue>
      </Information>
      {shopSession.data.user.role === 'regular_shopping' && (
        <>
          <Information>
            <InformationTitle>{t('views.admin.shop-session.info.total-net')}:</InformationTitle>
            <InformationValue>{shopSession.data.checkout.total_net}</InformationValue>
          </Information>
          <Information>
            <InformationTitle>{t('views.admin.shop-session.info.total-gross')}:</InformationTitle>
            <InformationValue>{shopSession.data.checkout.total_gross}</InformationValue>
          </Information>
        </>
      )}
    </TabContentSection>
  );
}

export default AdminPageShopSessionInfo;
