import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    font-size: 62.5%;
  }

  body {
    height: 100%;
    font-size: 1.6rem;
    margin: 0;
    font-family: 'Open Sans';
  }

  #root {
    height: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .photo-loader {
    margin: -22px;
  }

  .Toastify__progress-bar {
    background: linear-gradient(to right, #02ab6c, #00C798);
  }

  .tabs-component {
    div[role="tablist"] {

      button {
        background: transparent;
        border: 2px solid #ffffff;
        width: 100px;
        height: 40px;
        font-size: 16px;
        border-radius: 40px;
        color: #616161;
        cursor: pointer;
      
        &.active {
          border: 2px solid #02c798;
          background-color: #fff;
          color: #02c798;
        }

        &:hover {
          color: #02c798;
        }
      }
    }

    div[role="tabpanel"] {
      min-height: calc(100vh - 190px);
      padding: 10px;
      border-radius: 0 0 5px 5px;
      border-top: 0;
    }
  }
`;

export default GlobalStyle;
