import React, { useEffect } from 'react';
import styled from 'styled-components';
import UserContainer from 'components/atoms/containers/userContainer/UserContainer';
import UserImage from 'components/atoms/others/userImage/UserImage';
import Heading from 'components/atoms/texts/heading/Heading';
import AccountStatisticWidget from 'components/organisms/accountStatisticWidget/AccountStatisticWidget';
import { ImStopwatch, ImStatsDots, ImCart, ImUsers } from 'react-icons/im';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboard } from 'features/dashboard/thunks';

const StyledUserContainer = styled(UserContainer)`
  ${Heading} {
    text-align: center;
  }
`;

const StyledUserImage = styled(UserImage)`
  margin: 10px auto;
`;

function UserWidget() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const dashboard = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(fetchDashboard());
  }, [dispatch]);

  return (
    <StyledUserContainer>
      <StyledUserImage avatar={currentUser.avatar} />
      <Heading $colorType="secondary">
        {currentUser.first_name} {currentUser.last_name}
      </Heading>

      <AccountStatisticWidget
        name={t('components.organisms.user-widget.sessions-count')}
        count={dashboard.sessions_total}
      >
        <ImUsers />
      </AccountStatisticWidget>

      <AccountStatisticWidget
        name={t('components.organisms.user-widget.average-income')}
        count={`${dashboard.income_average / 100} PLN`}
      >
        <ImStatsDots />
      </AccountStatisticWidget>

      <AccountStatisticWidget
        name={t('components.organisms.user-widget.average-products')}
        count={
          dashboard.picked_products_average_number &&
          dashboard.picked_products_average_number.toFixed(1)
        }
      >
        <ImCart />
      </AccountStatisticWidget>

      <AccountStatisticWidget
        name={t('components.organisms.user-widget.average-order')}
        count={`${dashboard.duration_average} s`}
      >
        <ImStopwatch />
      </AccountStatisticWidget>
    </StyledUserContainer>
  );
}

export default UserWidget;
