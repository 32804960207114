import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyUuid } from 'features/app/utils/authentication';

import api from 'config/api';

export const fetchShopSession = createAsyncThunk(
  'shopSession/fetchShopSession',
  async (payload) => {
    const { data: shopSession } = await api.get(
      `/companies/${getCompanyUuid()}/shops/${payload.shopId}/shop_sessions/${
        payload.shopSessionId
      }`,
    );
    return shopSession;
  },
);
