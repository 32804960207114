import ProductPhotoPreview from 'components/atoms/others/productPhotoPreview/ProductPhotoPreview';

export const columns = [
  {
    key: 'photo',
    dataKey: 'photo',
    titleTranslationKey: 'views.admin.products.columns.product-photo',
    cellRenderer: ({ cellData: photo }) => <ProductPhotoPreview photo={photo} />,
    width: 100,
  },
  {
    key: 'name',
    dataKey: 'name',
    titleTranslationKey: 'views.admin.products.columns.product-name',
    width: 400,
  },
  {
    key: 'sku',
    dataKey: 'sku',
    titleTranslationKey: 'views.admin.products.columns.sku',
    width: 400,
  },
  {
    key: 'price',
    dataKey: 'price',
    titleTranslationKey: 'views.admin.products.columns.price',
    width: 400,
  },
];
