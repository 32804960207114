const MainTheme = {
  colors: {
    primary: {
      background: '#FFFFFF',
      color: '#00C798',
      hover: '#03AC84',
    },
    secondary: {
      background: '#00C798',
      color: '#616161',
      hover: '#BFC3C1',
    },
    tertiary: {
      background: '#00C798',
      color: '#FFFFFF',
      hover: '#ffffff8f',
    },
    detail: {
      background: '#eeeeee',
      color: '#FFFFFF',
      hover: '#ffffff8f',
      shadow: '#d3d1d1',
    },
    failure: {
      color: '#ef4444',
    },
  },
  fontSize: {
    big: '3rem',
    medium: '2.8rem',
    small: '1.8rem',
    xsmall: '1.6rem',
  },
  fontWeight: {
    thin: 100,
    light: 300,
    normal: 400,
    semiBold: 600,
    bold: 700,
  },
  sidebar: {
    width: '280px',
    mobileWidth: '88px',
  },
  shadows: {
    base: '0 15px 35px 0 rgba(0, 0, 0, 0.07), 0 5px 15px 0 rgba(0, 0, 0, 0.11)',
    small: '0 7px 17px 0 rgba(0, 0, 0, 0.07), 0 5px 15px 0 rgba(0, 0, 0, 0.11)',
  },
  utils: {
    borderRadius: 6,
    space: 15,
    transition: '0.2s',
  },
};

export default MainTheme;
