// @flow
import React, { useContext, useState } from 'react';
import Auth from 'components/organisms/layouts/auth/Auth';
import Input from 'components/atoms/others/input/Input';
import Button from 'components/atoms/others/button/Button';
import AuthForm from 'components/organisms/forms/auth/Auth';
import styled from 'styled-components';
import { AuthContext } from 'features/app/contexts/authentication';
import { useNavigate, Navigate } from 'react-router-dom';
import { Routes as routes } from 'constants/Routes';
import { useTranslation } from 'react-i18next';

const PasswordInput = styled(Input)`
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const ErrorText = styled.span`
  margin-top: ${({ theme }) => theme.utils.space}px;
  text-align: center;
  color: ${({ theme }) => theme.colors.failure.color};
`;

function SignInPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isAuthenticated, isLoading, isError, signIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const payload = { email, password };
  const { t } = useTranslation();

  const onSubmit = (event) => {
    event.preventDefault();
    signIn({ payload, navigate });
  };

  if (isAuthenticated) {
    return <Navigate to={routes.ADMIN_HOME} />;
  }

  return (
    <Auth>
      <AuthForm header={t('views.sign-in.sign-in')} onSubmit={onSubmit}>
        <Input
          placeholder={t('views.sign-in.email')}
          value={email}
          onChange={(value) => setEmail(value)}
        />
        <PasswordInput
          placeholder={t('views.sign-in.password')}
          type="password"
          value={password}
          onChange={(value) => setPassword(value)}
        />
        <StyledButton isLoading={isLoading} disabled={isLoading}>
          {t('views.sign-in.sign-in')}
        </StyledButton>

        {isError && <ErrorText>{t('errors.something-went-wrong')}</ErrorText>}
      </AuthForm>
    </Auth>
  );
}

export default SignInPage;
