import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateCurrentUser } from 'features/currentUser/thunks';
import { toast } from 'react-toastify';
import { setUser } from 'features/app/utils/authentication';

import styled from 'styled-components';
import Dashboard from 'components/organisms/layouts/dashboard/Dashboard';
import DashboardHeadings from 'components/molecules/dashboardHeadings/DashboardHeadings';
import Avatar from 'react-avatar-edit';
import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';
import FormField from 'components/molecules/forms/formField/FormField';
import Input from 'components/atoms/others/input/Input';
import Button from 'components/atoms/others/button/Button';
import Select from 'components/atoms/others/select/Select';
import i18n from 'config/i18n';

const SettingsUserContainer = styled.div`
  ${Button.Styled} {
    margin-top: ${({ theme }) => theme.utils.space}px;
  }
`;

const AvatarHoldar = styled.div`
  max-width: 150px;
  overflow: hidden;
`;

function AdminPageProfile() {
  const baseImage = 'data:image/png;base64,';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser);
  const [avatar, setAvatar] = useState(baseImage);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [locale, setLocale] = useState('en');
  const [isLoading, setIsLoading] = useState(true);
  const menuOptions = [
    { displayValue: t('views.admin.profile.languages.en'), value: 'en' },
    { displayValue: t('views.admin.profile.languages.pl'), value: 'pl' },
    { displayValue: t('views.admin.profile.languages.de'), value: 'de' },
  ];

  const handleCrop = (data) => {
    setAvatar(data);
  };

  const handleClose = (data) => {
    setAvatar(baseImage);
  };

  useEffect(() => {
    if (user) {
      if (user.avatar) {
        setAvatar(user.avatar);
      }
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setLocale(user.locale);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await dispatch(updateCurrentUser({ id: user.id, first_name, last_name, avatar, locale }));

    if (i18n.language !== locale) {
      i18n.changeLanguage(locale);
      setUser({ locale });
    }

    toast(t('notifications.profile_updated'));
  };

  return (
    <Dashboard>
      <DashboardHeadings
        headingText={t('views.admin.profile.heading')}
        subheadingText={t('views.admin.profile.subheading')}
      />

      <SettingsUserContainer>
        <TabContentSection>
          <form onSubmit={handleSubmit}>
            {user.invite_email && avatar && !isLoading && (
              <FormField label={t('views.admin.profile.avatar')}>
                <AvatarHoldar>
                  <Avatar
                    width={150}
                    height={150}
                    onCrop={handleCrop}
                    onClose={handleClose}
                    exportAsSquare={true}
                    exportQuality={0.3}
                    src={avatar === baseImage ? null : avatar}
                  />
                </AvatarHoldar>
              </FormField>
            )}

            <FormField marginTop label={t('views.admin.profile.name')}>
              <Input value={first_name} onChange={setFirstName} />
            </FormField>

            <FormField marginTop label={t('views.admin.profile.lastname')}>
              <Input value={last_name} onChange={setLastName} />
            </FormField>

            <FormField marginTop label={t('views.admin.profile.language')}>
              <Select options={menuOptions} value={locale} onChange={setLocale} />
            </FormField>

            <Button type="submit" isLoading={user.status === 'updating'}>
              {t('common.save')}
            </Button>
          </form>
        </TabContentSection>
      </SettingsUserContainer>
    </Dashboard>
  );
}

export default AdminPageProfile;
