// @flow
import React, { Component } from 'react';
import Dashboard from 'components/organisms/layouts/dashboard/Dashboard';
import DashboardHeadings from 'components/molecules/dashboardHeadings/DashboardHeadings';
import EmptyResource from 'components/molecules/emptyResource/EmptyResource';
import { withTranslation } from 'react-i18next';

export class AdminPageUsers extends Component {
  render() {
    const { t } = this.props;

    return (
      <Dashboard>
        <DashboardHeadings
          headingText={t('views.admin.users.heading')}
          subheadingText={t('views.admin.users.subheading')}
        />
        <EmptyResource resource="users" />
      </Dashboard>
    );
  }
}

export default withTranslation()(AdminPageUsers);
