import { useMemo } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

import styled from 'styled-components';
import Dropdown from 'components/atoms/others/dropdown/Dropdown';

const SelectValue = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  border: 1px solid #c7c8ca;
  border-radius: ${({ theme }) => theme.utils.borderRadius}px;
`;

function Select({ options, placement, value, onChange }) {
  const displayValue = useMemo(() => {
    const currentOption = options.find((option) => option.value === value);

    if (currentOption) {
      return currentOption.displayValue;
    }

    return '';
  }, [options, value]);

  const optionsForDropdown = useMemo(() => {
    return options.map((option) => ({
      ...option,
      selected: option.value === value,
    }));
  }, [options, value]);

  return (
    <Dropdown options={optionsForDropdown} placement={placement} onClick={onChange}>
      <SelectValue>
        {displayValue}
        <MdKeyboardArrowDown size={'1.4em'} color="#c7c8ca" />
      </SelectValue>
    </Dropdown>
  );
}

export default Select;
