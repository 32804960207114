import { createSlice } from '@reduxjs/toolkit';
import { fetchDashboard } from './thunks';

const initialState = {
  duration_average: 0,
  income_average: 0,
  picked_products_average_number: 0,
  sessions_total: 0,
  status: 'loading',
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchDashboard.fulfilled, (state, action) => {
      const { payload } = action;
      const { duration_average, income_average, picked_products_average_number, sessions_total } =
        payload;

      state.duration_average = duration_average;
      state.income_average = income_average;
      state.picked_products_average_number = picked_products_average_number;
      state.sessions_total = sessions_total;
      state.status = 'success';
    });
  },
});

export default dashboardSlice.reducer;
