import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Routes as routes } from 'constants/Routes';
import { fetchProduct, updateProduct } from 'features/products/thunks';
import { toast } from 'react-toastify';

import RegularModal from 'components/molecules/modals/regular/regularModal';
import FormField from 'components/molecules/forms/formField/FormField';
import DropPhotoField from 'components/molecules/forms/dropPhotoField/DropPhotoField';
import Input from 'components/atoms/others/input/Input';

function AdminPageShowProduct() {
  const { t } = useTranslation();
  const { productId } = useParams();

  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [price, setPrice] = useState('');
  const [photo, setPhoto] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);

  const handleClose = useCallback(() => {
    navigate(routes.ADMIN_PRODUCTS);
  }, [navigate]);

  const product = useMemo(() => {
    return products.collection.find((reduxProduct) => reduxProduct.id === parseInt(productId, 10));
  }, [productId, products.collection]);

  useEffect(() => {
    if (product) {
      setName(product.name);
      setSku(product.sku);
      setPrice(product.price);
      setPhoto(product.photo);
    }
  }, [product]);

  useEffect(() => {
    dispatch(fetchProduct({ id: productId }));
  }, [dispatch, productId]);

  const handleSubmit = async () => {
    await dispatch(updateProduct({ id: productId, name, sku, price, photo }));
    toast(t('notifications.product_updated'));
    navigate(routes.ADMIN_PRODUCTS);
  };

  return (
    <RegularModal
      header={name}
      submitText={t('common.save')}
      onSubmit={handleSubmit}
      onClose={handleClose}
    >
      {product && (
        <>
          <FormField spaceAround>
            <DropPhotoField
              photoUrl={photo || product.photo}
              onUploadFinish={(url) => {
                setPhoto(url);
              }}
            ></DropPhotoField>
          </FormField>

          <FormField marginTop spaceAround label={t('views.admin.products.columns.product-name')}>
            <Input value={name} onChange={setName} />
          </FormField>

          <FormField marginTop spaceAround label={t('views.admin.products.columns.sku')}>
            <Input value={sku} onChange={setSku} />
          </FormField>

          <FormField marginTop spaceAround label={t('views.admin.products.columns.price')}>
            <Input value={price} onChange={setPrice} type="number" />
          </FormField>
        </>
      )}
    </RegularModal>
  );
}

export default AdminPageShowProduct;
