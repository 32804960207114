import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShop } from 'features/shops/thunks';

export function useShop(rawShopId) {
  const dispatch = useDispatch();
  const shops = useSelector((state) => state.shops);
  const shopId = parseInt(rawShopId, 10);

  const shop = useMemo(() => {
    return shops.collection.find((reduxShop) => reduxShop.id === parseInt(shopId, 10));
  }, [shopId, shops.collection]);

  useEffect(() => {
    if (!shop && shopId) {
      dispatch(fetchShop({ id: shopId }));
    }
  }, [dispatch, shop, shopId]);

  if (!shop) {
    return null;
  }

  return shop;
}
