import { createSlice } from '@reduxjs/toolkit';
import { fetchShopSessions } from './thunks';

const initialState = {
  collection: [],
  status: 'idle',
};

const shopSessionsSlice = createSlice({
  name: 'shopSessions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchShopSessions.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchShopSessions.fulfilled, (state, action) => {
      const { payload } = action;

      state.collection = payload;
      state.status = 'success';
    });
  },
});

export default shopSessionsSlice.reducer;
