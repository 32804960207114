import styled from 'styled-components';

const Separator = styled.div`
  width: ${({ $multipleCol }) => ($multipleCol ? 'calc(100% - 80px)' : '100%')};
  height: 1px;
  background-color: ${({ theme, $colorType }) =>
    $colorType ? theme.colors[$colorType].color : 'inherit'};
  margin: 20px 0;
  opacity: 0.3;
`;

export default Separator;
