import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import RegularModal from 'components/molecules/modals/regular/regularModal';
import FormField from 'components/molecules/forms/formField/FormField';
import Button from 'components/atoms/others/button/Button';
import AvatarEditor from 'react-avatar-editor';

const HoverContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0;
  font-size: ${({ theme }) => theme.fontSize.big};
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  transition: ${({ theme }) => theme.utils.transition};
`;

const Wrapper = styled.div`
  width: 800px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  border: 2px dotted ${({ theme }) => theme.colors.secondary.color};
  border-radius: ${({ theme }) => theme.utils.borderRadius}px;
  cursor: pointer;

  &:hover {
    ${HoverContent} {
      opacity: 1;
    }
  }
`;

const EditorWrapper = styled.div`
  padding: ${({ theme }) => theme.utils.space}px;

  ${Button.Styled} {
    margin-right: auto;
  }
`;

const EmptyState = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px dotted ${({ theme }) => theme.colors.secondary.color};
  border-radius: ${({ theme }) => theme.utils.borderRadius}px;
`;

const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: -20px;
  line-height: 60px;
  text-align: center;
  opacity: 0;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
`;

const Image = styled.img`
  width: 800px;
`;

const ImageEditor = ({ image, onSave, imageNoun, emptyLabel }) => {
  const { t } = useTranslation();
  const editorRef = useRef();
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [scale, setScale] = useState(1);
  const [tempImage, setTempImage] = useState();
  const [editModalOpened, setEditModalOpened] = useState(false);

  useEffect(() => {
    setTempImage(image);
  }, [image]);

  const onPositionChange = useCallback((position) => {
    setPosition(position);
  }, []);

  const onScaleChange = useCallback((event) => {
    const value = parseFloat(event.target.value);
    setScale(value);
  }, []);

  const onDeleteImage = useCallback(() => {
    setTempImage(null);
  }, []);

  const onFileUpload = useCallback(async (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => setTempImage(reader.result);
  }, []);

  const onSubmit = useCallback(() => {
    const imageData = tempImage ? editorRef.current.getImageScaledToCanvas().toDataURL() : null;
    onSave(imageData);
    setEditModalOpened(false);
    setScale(1);
  }, [tempImage, onSave]);

  if (editModalOpened) {
    return (
      <RegularModal
        header={t('components.molecules.image-editor.modal-header', { noun: imageNoun })}
        submitText={t('common.save')}
        maxWidth={870}
        onSubmit={onSubmit}
        onClose={() => setEditModalOpened(false)}
      >
        <EditorWrapper>
          <FormField label={t('')}>
            {tempImage ? (
              <AvatarEditor
                ref={editorRef}
                image={tempImage}
                width={800}
                height={600}
                border={15}
                scale={scale}
                rotate={0}
                position={position}
                onPositionChange={onPositionChange}
              />
            ) : (
              <EmptyState>
                {emptyLabel}
                <FileInput
                  type="file"
                  id="photo"
                  name="file"
                  accept="image/png"
                  onChange={onFileUpload}
                />
              </EmptyState>
            )}
          </FormField>
          <FormField marginTop label={t('components.molecules.image-editor.scale')}>
            <input
              name="scale"
              type="range"
              onChange={onScaleChange}
              min="0.1"
              max="2"
              step="0.01"
              value={scale}
            />
          </FormField>
          <FormField
            marginTop
            label={t('components.molecules.image-editor.delete-current', { noun: imageNoun })}
          >
            <Button onClick={onDeleteImage}>{t('common.delete')}</Button>
          </FormField>
        </EditorWrapper>
      </RegularModal>
    );
  }

  return (
    <Wrapper>
      <Image src={image} />

      <HoverContent onClick={() => setEditModalOpened(true)}>
        {t('components.molecules.image-editor.click-to-edit')}
      </HoverContent>
    </Wrapper>
  );
};

export default memo(ImageEditor);
