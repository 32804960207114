import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useShopSession } from 'features/shopSession/hooks';
import { Outlet } from 'react-router-dom';

import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';
import FullSizeTable from 'components/molecules/table/fullSizeTable/FullSizeTable';

const columns = [
  {
    key: 'cam_name',
    dataKey: 'cam_name',
    titleTranslationKey: 'views.admin.shop-session.videos.camera-name',
    width: 200,
  },
];

function AdminPageShopSessionVideos() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { shopId, shopSessionId } = useParams();
  const shopSession = useShopSession(shopId, shopSessionId);

  const translatedColumns = columns.map((column) => ({
    ...column,
    title: t(column['titleTranslationKey']),
  }));

  if (!shopSession) {
    return null;
  }

  return (
    <>
      <TabContentSection sectionTitle={t('views.admin.shop-session.videos.heading')}>
        <FullSizeTable
          data={shopSession.video_urls}
          columns={translatedColumns}
          rowEventHandlers={{
            onClick: ({ rowData }) =>
              navigate(
                `/admin/shops/${shopId}/sessions/${shopSessionId}/videos/${rowData.cam_name}`,
              ),
          }}
        />
      </TabContentSection>

      <Outlet />
    </>
  );
}

export default AdminPageShopSessionVideos;
