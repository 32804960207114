import { DevicesMinWidth } from 'constants/Devices';

import styled from 'styled-components';
import Sidebar from 'components/atoms/containers/sidebar/Sidebar';
import SidebarMenu from 'components/organisms/sidebarMenu/SidebarMenu';
import Content from 'components/atoms/containers/content/Content';
import FullWidth from 'components/atoms/containers/fullWidth/FullWidth';

const StyledContent = styled(Content)`
  margin-left: ${({ theme }) => theme.sidebar.mobileWidth};

  @media ${DevicesMinWidth.laptop} {
    margin-left: ${({ theme }) => theme.sidebar.width};
  }
`;

function Dashboard({ children }) {
  return (
    <FullWidth>
      <Sidebar>
        <SidebarMenu />
      </Sidebar>
      <StyledContent>{children}</StyledContent>
    </FullWidth>
  );
}

export default Dashboard;
