import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from 'features/products/thunks';
import { columns } from 'constants/tables/Products';
import { Routes as routes } from 'constants/Routes';
import { useNavigate, Outlet } from 'react-router-dom';

import Dashboard from 'components/organisms/layouts/dashboard/Dashboard';
import DashboardHeadings from 'components/molecules/dashboardHeadings/DashboardHeadings';
import EmptyResource from 'components/molecules/emptyResource/EmptyResource';
import FullSizeTable from 'components/molecules/table/fullSizeTable/FullSizeTable';
import LayoutLoader from 'components/molecules/loader/layout/LayoutLoader';
import LinkButton from 'components/atoms/others/linkButton/LinkButton';

function AdminPageProducts() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const translatedColumns = columns.map((column) => ({
    ...column,
    title: t(column['titleTranslationKey']),
  }));

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <Dashboard>
      <DashboardHeadings
        headingText={t('views.admin.products.heading')}
        subheadingText={t('views.admin.products.subheading')}
        rightContent={
          <LinkButton
            path={routes.ADMIN_PRODUCTS_NEW}
            text={t('views.admin.new-product.heading')}
          />
        }
      />

      {products.status === 'loading' ? (
        <LayoutLoader height={400} />
      ) : products?.collection?.length ? (
        <FullSizeTable
          data={products.collection}
          columns={translatedColumns}
          rowEventHandlers={{
            onClick: ({ rowData }) => navigate(`/admin/products/show/${rowData.id}`),
          }}
        />
      ) : (
        <EmptyResource resource="products" />
      )}

      <Outlet />
    </Dashboard>
  );
}

export default AdminPageProducts;
