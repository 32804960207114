import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

const NavLinkHolder = styled(NavLink)`
  padding: 14px;
  color: ${({ theme }) => theme.colors.secondary.color};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  border: 2px solid ${({ theme }) => theme.colors.detail.background};
  border-radius: ${({ theme }) => theme.utils.borderRadius}px;
  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.colors.primary.color};
    border: 2px solid ${({ theme }) => theme.colors.primary.color};
  }
`;

function RouteTab({ to, label }) {
  return <NavLinkHolder to={to}>{label}</NavLinkHolder>;
}

export default RouteTab;
