import React from 'react';
import FlexCenterContainer from 'components/atoms/containers/flexCenterContainer/FlexCenterContainer';
import ResourcesPlaceholder from 'components/atoms/others/resourcesPlaceholder/ResourcesPlaceholder';

import NoShops from 'assets/images/emptyStates/missingShop.svg';
import NoUsers from 'assets/images/emptyStates/missingUsers.svg';
import NoProducts from 'assets/images/emptyStates/missingProducts.svg';
import NoHealth from 'assets/images/emptyStates/missingHealth.svg';
import styled from 'styled-components';

const EmptyResourceHolder = styled.div`
  ${FlexCenterContainer} {
    min-height: 80vh;
  }
`;

function renderIcon(resource) {
  switch (resource) {
    case 'shops':
      return NoShops;
    case 'users':
      return NoUsers;
    case 'products':
      return NoProducts;
    case 'health':
      return NoHealth;
    default:
      return '';
  }
}

function EmptyResource({ resource }) {
  return (
    <EmptyResourceHolder>
      <FlexCenterContainer>
        <ResourcesPlaceholder $placeholderImage={renderIcon(resource)} />
      </FlexCenterContainer>
    </EmptyResourceHolder>
  );
}

export default EmptyResource;
