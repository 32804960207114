import React from 'react';
import styled from 'styled-components';
import TabHeading from 'components/atoms/texts/tabHeading/TabHeading';
import TabContent from 'components/atoms/containers/tabContent/TabContent';

const TabContentSectionContainer = styled.div`
  position: relative;
`;

function TabContentSection({ sectionTitle, children }) {
  return (
    <TabContentSectionContainer>
      <TabHeading $colorType="secondary">{sectionTitle}</TabHeading>
      <TabContent>{children}</TabContent>
    </TabContentSectionContainer>
  );
}

export default TabContentSection;
