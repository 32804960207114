import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useShopSession } from 'features/shopSession/hooks';

import styled from 'styled-components';
import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';

const IFrameContainer = styled.iframe`
  width: 100%;
  height: 700px;
  outline: none;
  border: none;
  background: ${({ theme }) => theme.colors.detail.background};
`;

function AdminPageShopSessionReceipt() {
  const { t } = useTranslation();
  const { shopId, shopSessionId } = useParams();
  const shopSession = useShopSession(shopId, shopSessionId);

  if (!shopSession) {
    return null;
  }

  return (
    <TabContentSection sectionTitle={t('views.admin.shop-session.receipt.heading')}>
      <IFrameContainer
        title={`receipt-${shopSession.data.uuid}`}
        src={`${process.env.REACT_APP_GLOBAL_API_HOST}/global_app/eparagons/${shopSession.data.uuid}`}
      />
    </TabContentSection>
  );
}

export default AdminPageShopSessionReceipt;
