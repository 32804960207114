import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useShopSession } from 'features/shopSession/hooks';
import { LazyLog } from 'react-lazylog';

import styled from 'styled-components';
import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';

const LogContainer = styled.div`
  height: 100rem;
`;

const LogLink = styled.a`
  display: inline-flex;
  margin-bottom: ${({ theme }) => theme.utils.space}px;
  transition: All ${({ theme }) => theme.utils.transition};

  &:hover {
    color: ${({ theme }) => theme.colors.primary.color};
  }
`;

const EmptyText = styled.p`
  font-style: italic;
`;

function AdminPageShopSessionLogs() {
  const { t } = useTranslation();
  const { shopId, shopSessionId } = useParams();
  const shopSession = useShopSession(shopId, shopSessionId);

  const url = useMemo(() => {
    if (shopSession.session_log_url) {
      return shopSession.session_log_url[0];
    }

    return null;
  }, [shopSession]);

  if (!shopSession) {
    return null;
  }

  return (
    <TabContentSection sectionTitle={t('views.admin.shop-session.logs.heading')}>
      <LogContainer>
        {url ? (
          <>
            <LogLink target="_blank" href={`${url}`}>
              {t('views.admin.shop-session.logs.open-link')}
            </LogLink>

            <LazyLog
              url={url}
              fetchOptions={{
                method: 'GET',
                headers: {
                  'Access-Control-Request-Method': 'GET',
                },
              }}
            />
          </>
        ) : (
          <EmptyText>{t('common.no-data')}</EmptyText>
        )}
      </LogContainer>
    </TabContentSection>
  );
}

export default AdminPageShopSessionLogs;
