import React, { useContext } from 'react';
import styled from 'styled-components';
import { DevicesMinWidth } from 'constants/Devices';
import { useTranslation } from 'react-i18next';
import { Routes as routes } from 'constants/Routes';
import { useSelector } from 'react-redux';
import { AuthContext } from 'features/app/contexts/authentication';
import { useNavigate } from 'react-router-dom';
import MenuItem from 'components/molecules/menu/menuItem/MenuItem';
import Dropdown from 'components/atoms/others/dropdown/Dropdown';
import UserImage from 'components/atoms/others/userImage/UserImage';

const MenuListHolder = styled.div`
  width: ${({ theme }) => theme.sidebar.mobileWidth};
  margin-top: ${({ theme }) => theme.utils.space}px;
  margin-bottom: 50px;
  padding-left: ${({ theme }) => theme.utils.space}px;
  padding-right: ${({ theme }) => theme.utils.space}px;

  @media ${DevicesMinWidth.laptop} {
    width: ${({ theme }) => theme.sidebar.width};
    margin-top: 0;
  }
`;

const DropdownItem = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0 20px;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.tertiary.color};
  transition: ${({ theme }) => theme.utils.transition};

  &:hover {
    color: ${({ theme }) => theme.colors.tertiary.hover};
  }
`;

const DropdownTextHolder = styled.span`
  display: none;

  @media ${DevicesMinWidth.laptop} {
    display: flex;
    margin-left: 20px;
  }
`;

function MenuList() {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.currentUser);
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);

  const menuOptions = [
    {
      displayValue: t('components.molecules.menu.menu-list.profile-settings'),
      path: routes.ADMIN_PROFILE,
    },
    {
      displayValue: t('components.molecules.menu.menu-list.logout'),
      onClick: () => signOut({ navigate }),
    },
  ];

  return (
    <MenuListHolder>
      <MenuItem
        name={t('components.molecules.menu.menu-list.home')}
        iconName="home"
        path={routes.ADMIN_HOME}
      />
      <MenuItem
        name={t('components.molecules.menu.menu-list.shops')}
        iconName="shops"
        path={routes.ADMIN_SHOPS}
      />
      <MenuItem
        name={t('components.molecules.menu.menu-list.users')}
        iconName="users"
        path={routes.ADMIN_USERS}
      />
      <MenuItem
        name={t('components.molecules.menu.menu-list.products')}
        iconName="products"
        path={routes.ADMIN_PRODUCTS}
      />
      <MenuItem
        name={t('components.molecules.menu.menu-list.company-settings')}
        iconName="settings"
        path={routes.ADMIN_SETTINGS}
      />
      <Dropdown options={menuOptions}>
        <DropdownItem>
          <UserImage avatar={currentUser.avatar} size="18" />
          <DropdownTextHolder>
            {t('components.molecules.menu.menu-list.my-profile')}
          </DropdownTextHolder>
        </DropdownItem>
      </Dropdown>
    </MenuListHolder>
  );
}

export default MenuList;
