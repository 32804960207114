import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyUuid } from 'features/app/utils/authentication';

import api from 'config/api';

export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  const { data: products } = await api.get(`/companies/${getCompanyUuid()}/products`);
  return products;
});

export const fetchProduct = createAsyncThunk('products/fetchProduct', async (payload) => {
  const { data: product } = await api.get(`/companies/${getCompanyUuid()}/products/${payload.id}`);
  return product;
});

export const updateProduct = createAsyncThunk('products/updateProduct', async (payload) => {
  const { data: product } = await api.put(
    `/companies/${getCompanyUuid()}/products/${payload.id}`,
    payload,
  );
  return product;
});

export const createProduct = createAsyncThunk('products/createProduct', async (payload) => {
  const { data: product } = await api.post(`/companies/${getCompanyUuid()}/products`, payload);
  return product;
});
