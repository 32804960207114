import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyUuid } from 'features/app/utils/authentication';

import api from 'config/api';

export const fetchCurrentCompany = createAsyncThunk(
  'currentCompany/fetchCurrentCompany',
  async () => {
    const { data: currentCompany } = await api.get(`/companies/${getCompanyUuid()}`);
    return currentCompany;
  },
);

export const updateCurrentCompany = createAsyncThunk(
  'currentCompany/updateCurrentCompany',
  async (payload) => {
    const { data: currentCompany } = await api.put(`/companies/${getCompanyUuid()}`, payload);
    return currentCompany;
  },
);
