import styled from 'styled-components';
import LogoImage from 'assets/images/logo.png';
import LogoWhiteImage from 'assets/images/logo_white.png';

const Logo = styled.div`
  width: 200px;
  height: 80px;
  min-height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ type }) => (type === 'default' ? LogoImage : LogoWhiteImage)});
`;

export default Logo;
