import React from 'react';
import styled from 'styled-components';
import Separator from 'components/atoms/others/separator/Separator';
import Heading from 'components/atoms/texts/heading/Heading';
import Subheading from 'components/atoms/texts/subheading/Subheading';

const DashboardHeadingsHolder = styled.div`
  position: relative;

  ${Heading} {
    margin: 0;
  }

  ${Subheading} {
    margin: 0;
    opacity: 0.8;
  }
`;

const LeftContent = styled.div``;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function DashboardHeading({ headingText, subheadingText, multipleCol, rightContent }) {
  return (
    <DashboardHeadingsHolder>
      <Wrapper>
        <LeftContent>
          <Heading $colorType="secondary">{headingText}</Heading>
          <Subheading $colorType="secondary">{subheadingText}</Subheading>
        </LeftContent>

        {rightContent}
      </Wrapper>

      <Separator $colorType="secondary" $multipleCol={multipleCol} />
    </DashboardHeadingsHolder>
  );
}

export default DashboardHeading;
