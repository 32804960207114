import styled from 'styled-components';
import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';

const VideoHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;

  img {
    width: 100%;
  }
`;

function AdminPageShopMonitoring() {
  return (
    <TabContentSection sectionTitle="Camera 1">
      <VideoHolder>
        <img src="https://shopbox-products.s3.eu-central-1.amazonaws.com/democamera.gif" alt="" />
      </VideoHolder>
    </TabContentSection>
  );
}

export default AdminPageShopMonitoring;
