import styled from 'styled-components';

const RouteTabsContainer = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: ${({ theme }) => theme.utils.space}px;
`;

export default RouteTabsContainer;
