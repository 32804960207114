import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createProduct } from 'features/products/thunks';
import { toast } from 'react-toastify';
import { Routes as routes } from 'constants/Routes';

import RegularModal from 'components/molecules/modals/regular/regularModal';
import FormField from 'components/molecules/forms/formField/FormField';
import DropPhotoField from 'components/molecules/forms/dropPhotoField/DropPhotoField';
import Input from 'components/atoms/others/input/Input';

function AdminPageNewProduct() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [price, setPrice] = useState('');
  const [photo, setPhoto] = useState('');

  const handleClose = useCallback(() => {
    navigate(routes.ADMIN_PRODUCTS);
  }, [navigate]);

  const handleSubmit = async () => {
    await dispatch(createProduct({ name, sku, price, photo }));
    toast(t('notifications.product_created'));
    handleClose();
  };

  return (
    <RegularModal
      header={t('views.admin.new-product.heading')}
      submitText={t('common.save')}
      onSubmit={handleSubmit}
      onClose={handleClose}
    >
      <FormField spaceAround>
        <DropPhotoField
          onUploadFinish={(url) => {
            setPhoto(url);
          }}
        ></DropPhotoField>
      </FormField>

      <FormField marginTop spaceAround label={t('views.admin.products.columns.product-name')}>
        <Input onChange={setName} />
      </FormField>

      <FormField marginTop spaceAround label={t('views.admin.products.columns.sku')}>
        <Input onChange={setSku} />
      </FormField>

      <FormField marginTop spaceAround label={t('views.admin.products.columns.price')}>
        <Input onChange={setPrice} type="number" />
      </FormField>
    </RegularModal>
  );
}

export default AdminPageNewProduct;
