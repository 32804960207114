export const Routes = {
  SIGN_IN: '/signin',
  ADMIN: '/admin',
  ADMIN_HOME: '/admin/home',
  ADMIN_SHOPS: '/admin/shops',
  ADMIN_SHOP: '/admin/shops/:shopId',
  // shop
  ADMIN_SHOP_INFO: '/admin/shops/:shopId/info',
  ADMIN_SHOP_PRODUCTS: '/admin/shops/:shopId/products',
  ADMIN_SHOP_ANALYTICS: '/admin/shops/:shopId/analytics',
  ADMIN_SHOP_SESSIONS: '/admin/shops/:shopId/sessions',
  ADMIN_SHOP_MONITORING: '/admin/shops/:shopId/monitoring',
  ADMIN_SHOP_SETTINGS: '/admin/shops/:shopId/settings',
  // shop session
  ADMIN_SHOP_SESSION: '/admin/shops/:shopId/sessions/:shopSessionId',
  ADMIN_SHOP_SESSION_INFO: '/admin/shops/:shopId/sessions/:shopSessionId/info',
  ADMIN_SHOP_SESSION_VIDEOS: '/admin/shops/:shopId/sessions/:shopSessionId/videos',
  ADMIN_SHOP_SESSION_VIDEO: '/admin/shops/:shopId/sessions/:shopSessionId/videos/:cameraName',
  ADMIN_SHOP_SESSION_LOGS: '/admin/shops/:shopId/sessions/:shopSessionId/logs',
  ADMIN_SHOP_SESSION_PURCHASED_PRODUCTS:
    '/admin/shops/:shopId/sessions/:shopSessionId/purchased-products',
  ADMIN_SHOP_SESSION_RECEIPT: '/admin/shops/:shopId/sessions/:shopSessionId/receipt',
  // others
  ADMIN_USERS: '/admin/users',
  ADMIN_PRODUCTS: '/admin/products',
  ADMIN_PRODUCTS_NEW: '/admin/products/new',
  ADMIN_PRODUCTS_SHOW: '/admin/products/show/:productId',
  ADMIN_HEALTH: '/admin/health',
  ADMIN_SETTINGS: '/admin/settings',
  ADMIN_PROFILE: '/admin/profile',
};
