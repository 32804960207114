import { Breakpoints as size } from './Breakpoints';

export const DevicesMinWidth = {
  phoneS: `(min-width: ${size.phoneS})`,
  phoneM: `(min-width: ${size.phoneM})`,
  phoneL: `(min-width: ${size.phoneL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};
