import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 60px;
  overflow: auto;
`;

export default Content;
