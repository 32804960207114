import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { columns } from 'constants/tables/ShopProducts';
import { useShop } from 'features/shops/hooks';

import TabContentSection from 'components/molecules/tabContentSection/TabContentSection';
import FullSizeTable from 'components/molecules/table/fullSizeTable/FullSizeTable';

function AdminPageShopProducts() {
  const { t } = useTranslation();
  let { shopId } = useParams();
  const shop = useShop(shopId);

  const translatedColumns = columns.map((column) => ({
    ...column,
    title: t(column['titleTranslationKey']),
  }));

  return (
    <TabContentSection sectionTitle={t('views.admin.shop.products.heading')}>
      <FullSizeTable data={shop.shop_products} columns={translatedColumns} />
    </TabContentSection>
  );
}

export default AdminPageShopProducts;
