import { jwtDecode } from 'jwt-decode';

export const isAuthenticated = () => {
  const authToken = localStorage.getItem('shopbox_auth_token');
  const expiry = localStorage.getItem('shopbox_auth_token_expiry');
  const isExpired = new Date(expiry * 1000) < new Date();

  return authToken && authToken.length && !isExpired;
};

export const setAuthData = (jsonWebToken) => {
  const { exp: expiry } = jwtDecode(jsonWebToken);

  localStorage.setItem('shopbox_auth_token', jsonWebToken);
  localStorage.setItem('shopbox_auth_token_expiry', expiry);
};

export const setCompany = ([companyData]) => {
  const companyUuid = companyData[0];

  localStorage.setItem('shopbox_company_uuid', companyUuid);
};

export const setUser = ({ locale }) => {
  localStorage.setItem('shopbox_user_locale', locale);
};

export const removeAuthData = () => {
  localStorage.removeItem('shopbox_auth_token');
  localStorage.removeItem('shopbox_auth_token_expiry');
  localStorage.removeItem('shopbox_company_uuid');
};

export const getAuthToken = () => {
  return localStorage.getItem('shopbox_auth_token');
};

export const getCompanyUuid = () => {
  return localStorage.getItem('shopbox_company_uuid');
};

export const getUser = () => {
  return {
    locale: localStorage.getItem('shopbox_user_locale'),
  };
};
