import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const DropdownHolder = styled.div``;

const Trigger = styled.div`
  cursor: pointer;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: fixed;
  list-style: none;
  border-radius: ${({ theme }) => theme.utils.borderRadius}px;
  box-shadow: ${({ theme }) => theme.shadows.base};
`;

const ListElement = styled.li`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.primary.color : theme.colors.secondary.color};
  background-color: ${({ theme }) => theme.colors.primary.background};
  transition: ${({ theme }) => theme.utils.transition};
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: ${({ theme }) => theme.utils.borderRadius}px;
    border-top-right-radius: ${({ theme }) => theme.utils.borderRadius}px;
  }

  &:last-of-type {
    border-bottom-left-radius: ${({ theme }) => theme.utils.borderRadius}px;
    border-bottom-right-radius: ${({ theme }) => theme.utils.borderRadius}px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.color};
    background-color: ${({ theme }) => theme.colors.detail.background};
  }
`;

const ListElementText = styled.span`
  width: 100%;
  display: flex;
  padding: ${({ theme }) => theme.utils.space}px;
`;

const getOnClickAction = (element, onClick) => {
  if (onClick) {
    if (element.value) {
      return () => onClick(element.value);
    } else {
      return onClick;
    }
  }

  if (element.onClick) {
    return element.onClick;
  }

  return null;
};

function Dropdown({ children, options, placement = {}, onClick }) {
  const [opened, setOpened] = useState(false);
  const [topPosition, setTopPosition] = useState(null);
  const [leftPosition, setLeftPosition] = useState(null);
  const triggerRef = useRef(null);
  const listRef = useRef(null);
  const margin = 15;
  const positioned = !!topPosition && !!leftPosition;

  useEffect(() => {
    const onWindowClick = () => {
      setOpened(false);
    };

    if (opened) {
      window.addEventListener('click', onWindowClick);
    }

    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, [opened]);

  useEffect(() => {
    if (!opened) {
      return;
    }

    const defaultPlacement = { top: false, left: true };
    const customPlacement = Object.assign(defaultPlacement, placement);

    const triggerRect = triggerRef.current.getBoundingClientRect();
    const listRect = listRef.current.getBoundingClientRect();

    let top,
      left = 0;

    if (customPlacement.top) {
      top = triggerRect.top - listRect.height - margin;
    } else {
      top = triggerRect.top + triggerRect.height + margin;
    }

    if (customPlacement.left) {
      left = triggerRect.left;
    } else {
      left = triggerRect.left + triggerRect.width + margin;
    }

    setTopPosition(top);
    setLeftPosition(left);
  }, [opened, placement]);

  const onTriggerClick = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  return (
    <DropdownHolder>
      <Trigger ref={triggerRef} onClick={onTriggerClick}>
        {children}
      </Trigger>

      {opened && (
        <List ref={listRef} style={{ top: topPosition, left: leftPosition }}>
          {positioned &&
            options.map((element, index) =>
              element.path ? (
                <ListElement key={index} selected={element.selected}>
                  <Link to={element.path}>
                    <ListElementText>{element.displayValue}</ListElementText>
                  </Link>
                </ListElement>
              ) : (
                <ListElement
                  key={index}
                  selected={element.selected}
                  onClick={getOnClickAction(element, onClick)}
                >
                  <ListElementText>{element.displayValue}</ListElementText>
                </ListElement>
              ),
            )}
        </List>
      )}
    </DropdownHolder>
  );
}

export default Dropdown;
