import React from 'react';
import styled from 'styled-components';

const AuthFormHolder = styled.form`
  min-width: 350px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary.background};
  border-radius: ${({ theme }) => theme.utils.borderRadius}px;
  box-shadow: ${({ theme }) => theme.shadows.base};
`;

const Header = styled.h2`
  margin: 0 0 20px 0;
`;

function AuthForm({ children, header, onSubmit }) {
  return (
    <AuthFormHolder onSubmit={onSubmit}>
      <Header>{header}</Header>
      {children}
    </AuthFormHolder>
  );
}

export default AuthForm;
