import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useShopSession } from 'features/shopSession/hooks';

import RegularModal from 'components/molecules/modals/regular/regularModal';
import FormField from 'components/molecules/forms/formField/FormField';

function AdminPageShopSessionVideo(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { shopId, shopSessionId, cameraName } = useParams();
  const shopSession = useShopSession(shopId, shopSessionId);
  const video = shopSession.video_urls.find((video) => video.cam_name === cameraName);

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <RegularModal
      header={t('views.admin.shop-session.video.heading', { cameraName })}
      onClose={handleClose}
    >
      <FormField
        marginTop
        spaceAround
        label={t('views.admin.shop-session.video.camera-name', { cameraName })}
      >
        {video.cam_name}
      </FormField>
      <FormField marginTop spaceAround label={t('views.admin.shop-session.video.video-name')}>
        <video width="570" height="320" controls>
          <source src={video.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </FormField>
    </RegularModal>
  );
}

export default AdminPageShopSessionVideo;
