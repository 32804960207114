// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import axios from 'axios';
import MainTheme from 'theme/MainTheme';

const DropPhotoFieldHolder = styled.div`
  display: flex;
  justify-content: center;
`;

const DropPhotoArea = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border: ${({ $isLoading }) => ($isLoading ? 'none' : '2px dashed #616161')};
  margin-top: 15px;
  height: 100px;
  position: relative;
  width: 100px;
  border-radius: 100px;
  line-height: 100px;
  cursor: pointer;
  transition: All ${({ theme }) => theme.utils.transition};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.color};
    color: ${({ theme }) => theme.colors.primary.color};
  }
`;

const InputCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: -20px;
  line-height: 60px;
  text-align: center;
  border-radius: 100px;
  line-height: 95px;
`;

const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: -20px;
  line-height: 60px;
  text-align: center;
  opacity: 0;
  border-radius: 100px;
  outline: none;
`;

const ProductPreview = styled.div`
  background: url('${({ src }) => src}') #e1e1e1;
  width: 100px;
  height: 100px;
  position: absolute;
  margin: -22px;
  border-radius: 100px;
  background-size: 80% 80%;
  background-repeat: no-repeat;
  background-position: center;
`;

function DropPhotoField({ onUploadFinish, photoUrl }) {
  const { t } = useTranslation();
  const [photo, setPhoto] = useState(photoUrl);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (el) => {
    const form = new FormData();
    const imagedata = el.target.files[0];
    const aiBackgroundRemoverUrl = process.env.REACT_APP_PHOTO_AI_BACKGROUND_REMOVER_URL;
    form.append('photo', imagedata);

    setIsLoading(true);
    setPhoto('');

    axios
      .request({
        method: 'POST',
        url: aiBackgroundRemoverUrl,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        },
        data: form,
      })
      .then(function (response) {
        setPhoto(response.data.file_url);
        onUploadFinish(response.data.file_url);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <DropPhotoFieldHolder>
      <DropPhotoArea $isLoading={isLoading}>
        {photo ? (
          <ProductPreview src={photo}></ProductPreview>
        ) : (
          <InputCover>
            {isLoading
              ? t('components.molecules.forms.drop-photo-field.loading')
              : t('components.molecules.forms.drop-photo-field.drop-file-here')}
          </InputCover>
        )}
        {isLoading ? (
          <ReactLoading
            type="spin"
            color={MainTheme.colors.primary.color}
            className="photo-loader"
            width="100"
            height="100"
          />
        ) : (
          <FileInput type="file" id="photo" name="file" accept="image/png" onChange={onChange} />
        )}
      </DropPhotoArea>
    </DropPhotoFieldHolder>
  );
}

export default DropPhotoField;
